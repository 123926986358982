<template>
  <div class="flex flex-col justify-center items-center min-h-screen">
    <h1 class="text-2xl font-bold">Not Found</h1>

    <router-link class="text-big-stone hover:text-blumine hover:underline" :to="{ name: 'Dashboard' }">Go Back</router-link>
  </div>
</template>

<script>
export default {
  name: '404'
}
</script>
