<template>
  <layout>
    <div>
      <div class="flex items-center">
        <img src="@/assets/images/mv-logo.svg" alt="My Vends 2.0" class="w-12 h-12 mr-2">
        <h2 class="text-4xl tracking-widest">MyVends</h2>
      </div>
      <p class="mt-2 text-lg max-w text-gray-500">
        Forgot password.
      </p>
    </div>

    <div class="mt-8">
      <div class="mt-6">
        <form class="space-y-6" @submit.prevent="forgot">
          <text-input v-model="email" type="email" name="email" label="Email address" placeholder="Email address" :error="errorMsg" :is-loading="isLoading" />

          <div class="flex items-center justify-between">
            <div class="text-sm leading-5">
              <router-link :to="{ name: 'Login' }" class="text-blumine underline hover:text-big-stone focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                Back to Login
              </router-link>
            </div>
          </div>

          <div>
            <span class="block w-full rounded-md shadow-sm">
              <button
                type="submit"
                :class="{ 'cursor-not-allowed': isLoading }"
                :disabled="isLoading"
                class="w-full flex justify-center items-center py-2 px-4 border border-transparent font-semibold text-base rounded-md text-white bg-big-stone hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out">
                <loading v-show="isLoading" class="w-5 h-5 mr-3" /> Reset password
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </layout>
</template>

<script>
import { Auth } from '@/services/auth.js'
import Layout from '@/layouts/Auth.vue'
import Loading from '@/components/icons/Loading.vue'
import TextInput from '@/components/form/Text.vue'

export default {
  components: {
    Layout,
    Loading,
    TextInput
  },

  data: () => ({
    isAuth: false,
    email: '',
    errorMsg: '',
    isLoading: false
  }),

  methods: {
    navigateToReset() {
      this.$router.push({ name: 'ResetPassword'})
    },
    async forgot() {
      this.isLoading = true

      try {
        await Auth.forgot(this.email)
        this.navigateToReset()
      } catch (error) {
        this.errorMsg = error.message
      }
      this.isLoading = false
    }
  }
}
</script>
